<template>
  <div class="w-50">
    <!--begin::Signin-->
    <div class="login-form login-signin">
      <div class="text-center mb-10 mb-lg-20">
        <p class="text-muted font-weight-semi-bold">
          Enter your new password
        </p>
      </div>

      <!--begin::Form-->
      <b-form class="form" @submit.stop.prevent="onSubmit">
        <b-form-group>
          <label>New Password</label>
          <b-form-input
            v-model="$v.form.password.$model"
            placeholder="New Password"
            type="password"
            :state="validateState('password')"
            class="form-control form-control-solid h-auto py-5 px-6"
            aria-describedby="input-2-live-feedback"
          />
        </b-form-group>

        <b-form-group>
          <label>Confirm New Password</label>
          <b-form-input
            v-model="$v.form.co_password.$model"
            placeholder="Confirm New Password"
            type="password"
            :state="validateState('co_password')"
            class="form-control form-control-solid h-auto py-5 px-6"
            aria-describedby="input-2-live-feedback"
          />
        </b-form-group>
        <div
          class="form-group d-flex flex-wrap justify-content-between align-items-center"
        >
          <a
            href="javascript:void(0)"
            class="text-dark-60 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
            @click="singIn"
          >
            Sign In
          </a>
          <button
            ref="kt_login_signin_submit"
            class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3"
          >
            Send
          </button>
        </div>
        <!--end::Action-->
      </b-form>
      <!--end::Form-->
    </div>
    <!--end::Signin-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { RESET_PASSWORD } from "@/core/services/store/auth.module";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  data() {
    return {
      form: {
        password: null,
        co_password: null,
      },
    };
  },
  validations: {
    form: {
      co_password: {
        required,
      },
      password: {
        required,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    singIn() {
      this.$router.push({ name: "login" });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.$bvToast.toast(this.$t("MESSAGE.ERROR.DESCRIPTION_NEW"), {
          title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
          variant: "danger",
          solid: true,
        });
      } else if (this.form.password !== this.form.co_password) {
        this.$bvToast.toast("Passwords do not match, try again!", {
          title: this.$t("MESSAGE.ERROR.DESCRIPTION_NEW"),
          variant: "danger",
          solid: true,
        });
      } else {
        const submitButton = this.$refs["kt_login_signin_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        const data = {
          token: this.$route.params.token,
          password: this.form.password,
          user_id: this.$route.params.id,
        };

        setTimeout(() => {
          this.$store
            .dispatch(RESET_PASSWORD, data)
            .then(data => {
              this.$bvToast.toast(data.message, {
                title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
                variant: "success",
                solid: true,
              });

              this.form = {
                co_password: null,
                password: null,
              };
              this.$nextTick(() => {
                this.$v.$reset();
              });
            })
            .catch(error => {
              if (typeof error.response !== "undefined") {
                if (typeof error.response.data.errors !== "undefined") {
                  for (let field of Object.keys(error.response.data.errors)) {
                    this.$bvToast.toast(error.response.data.errors[field][0], {
                      title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
                      variant: "danger",
                      solid: true,
                    });
                  }
                } else {
                  if (typeof error.response.data !== "undefined") {
                    this.$bvToast.toast(error.response.data.message, {
                      title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
                      variant: "danger",
                      solid: true,
                    });
                  }
                }
              }
            });
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        }, 2000);
      }
    },
  },
};
</script>
